import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToasterService } from '../../services/toaster.service';
import { FormControl } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Observable, map, startWith } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';



interface Location {
  id: number;
  name: string;
}
@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrl: './add-location.component.css'
})
export class AddLocationComponent implements OnInit {
  countryControl = new FormControl('');
  stateControl = new FormControl('');
  cityControl = new FormControl('');

  country: Location[] = [];
  states: Location[] = [];
  cities: Location[] = [];

  filteredCountryOptions!: Observable<Location[]>;
  filteredStateOptions!: Observable<Location[]>;
  filteredCityOptions!: Observable<Location[]>;
  city: any;
  state: any;
  countryId: any;



  constructor(public dialogRef: MatDialogRef<AddLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toaster: ToasterService,
    private userService: UserService) {
  }

  ngOnInit(): void {
    this.getCountry();
    this.searchCountryFilter();
  }

  private _filter(value: string, options: Location[]): Location[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
    return options.filter(option => option.name.toLowerCase().includes(filterValue));
  }


  displayFn(location: Location): string {
    return location && location.name ? location.name : '';
  }

  optionSelectedCountry(event: MatAutocompleteSelectedEvent): void {
    const selectedCountry = event.option.value;
    this.stateControl.setValue('');
    this.cityControl.setValue('');
    this.states = [];
    this.cities = [];
    this.getStates(selectedCountry.id);
    this.countryId = selectedCountry.id;
  }

  optionSelectedState(event: MatAutocompleteSelectedEvent): void {
    const selectedState = event.option.value;
    this.cityControl.setValue('');
    this.cities = [];
    this.getCity(selectedState.stateId);
    this.state = selectedState.stateId
  }

  optionSelectedCity(event: MatAutocompleteSelectedEvent): void {
    const selectedCity = event.option.value;
    console.log(selectedCity);
    this.city = selectedCity.cityId
  }

  async getCountry() {
    const res: any = await this.userService.getCountry().toPromise();
    this.country = res.data;
    this.searchCountryFilter();
  }

  async getStates(id: number) {
    const res: any = await this.userService.getStates(id).toPromise();
    this.states = res.data;
    this.searchStateFilter();
  }

  async getCity(id: number) {
    const res: any = await this.userService.getCity(id).toPromise();
    this.cities = res.data;
    this.searchCityFilter();
  }

  searchCountryFilter() {
    this.filteredCountryOptions = this.countryControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.country))
    );
  }

  searchStateFilter() {
    this.filteredStateOptions = this.stateControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.states))
    );
  }

  searchCityFilter() {
    this.filteredCityOptions = this.cityControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.cities))
    );
  }

  submit() {
    let payload = {
      countryId: this.countryId,
      stateId: this.state,
      cityId: this.city,
    }
    if(!payload.countryId || !payload.cityId || !payload.stateId){
      this.toaster.warning("Please Fill the required Fields");
      return
    }
    this.dialogRef.close(payload);

  }
  close() {
    this.dialogRef.close();

  }
}
