<h2 mat-dialog-title class="headerColor">Add City</h2>
<div class="icon">
  <img src="../../../../assets/images/close.png" (click)="close()" class="close-icon" />
</div>
<mat-dialog-content>
  <form class="example-form">
    <mat-form-field class="example-full-width w-100">
      <mat-label>Country <span class="text-danger">*</span></mat-label>
      <input type="text" aria-label="Country" matInput [formControl]="countryControl" [matAutocomplete]="countryAuto">
      <mat-autocomplete #countryAuto="matAutocomplete" [displayWith]="displayFn"
        (optionSelected)="optionSelectedCountry($event)">
        <mat-option *ngFor="let option of filteredCountryOptions | async" [value]="option">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="example-full-width w-100">
      <mat-label>State <span class="text-danger">*</span></mat-label>
      <input type="text" aria-label="State" maxlength="20" matInput [formControl]="stateControl"
        [matAutocomplete]="stateAuto">
      <mat-autocomplete #stateAuto="matAutocomplete" [displayWith]="displayFn"
        (optionSelected)="optionSelectedState($event)">
        <mat-option *ngFor="let option of filteredStateOptions | async" [value]="option">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="example-full-width w-100">
      <mat-label>City<span class="text-danger">*</span></mat-label>
      <input type="text" aria-label="City" maxlength="20" matInput [formControl]="cityControl"
        [matAutocomplete]="cityAuto">
      <mat-autocomplete #cityAuto="matAutocomplete" [displayWith]="displayFn"
        (optionSelected)="optionSelectedCity($event)">
        <mat-option *ngFor="let option of filteredCityOptions | async" [value]="option">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
  <div>
    <button mat-raised-button type="submit" class="btnText" (click)="submit()" color="primary">Add</button>
    <button mat-raised-button (click)="close()">Close</button>
  </div>
</mat-dialog-actions>